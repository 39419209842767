<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)">
      <ion-header>
        <ion-toolbar>
          <ion-title>Vitals</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-card class="main-card">
        <ion-card-header>
          <ion-card-title>Vitals</ion-card-title>
          <p>Add your latest Vitals and track your health.</p>
        </ion-card-header>

        <router-link :to="{ path: `/member/vitals/upload` }" class="unselected router-button" style="margin-right: 12px;">Uploaded Documents</router-link>
        <router-link :to="{ path: `/member/vitals/add` }" class="new-vitals router-button">Add New Vitals</router-link>

        <ion-card-content>
          <ion-grid>
            <ion-row>
              <ion-col width-100>
                <ion-card
                  class="vitals"
                  style="background: url(/assets/icon/vitals-heart-rate.svg) no-repeat, #cb3333;background-size: 134px;background-position-y: bottom;"
                >
                  <span class="vital-title">Heart Rate</span>
                  <router-link :to="{ path: `/member/vitals/heartrate` }" class="vital-link" style="float: right"
                    >VIEW STATS</router-link
                  >
                  
                  <div class="vital-value" style="font-size: 67px;padding-left: 39%;" v-if="vitals.vitals.latest_heart_rate">
                    {{vitals.vitals.latest_heart_rate.value}}<span
                      class="vital-measurement"
                      style="font-size: 20px; line-height: 30px"
                      >bpm</span
                    >
                  </div>

                  <div v-else class="no-vitals" style="padding-bottom:40px;">
                    <ion-chip>
                      <ion-label>Nothing Logged</ion-label>
                    </ion-chip>
                    <br>
                    <router-link :to="{ path: '/member/vitals/add' }" class="vital-link" style="float: none;">ADD Vitals</router-link>
                  </div>
                  

                </ion-card>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col width-50>
                <ion-card
                  class="vitals"
                  style="background: url(/assets/icon/vitals-blood-pressure.svg) no-repeat, #eeeeee; color: var(--ion-color-primary);background-size: 100px;background-position-y: bottom;"
                >
                  <span class="vital-title" style="color: #000000"
                    >Blood Pressure</span
                  >
                  <br />
                  <router-link :to="{ path: `/member/vitals/bloodpressure` }" class="vital-link" style="color: var(--ion-color-primary)"
                    >VIEW STATS</router-link
                  >
  
                  <div class="vital-value" v-if="vitals.vitals.latest_blood_pressure">{{vitals.vitals.latest_blood_pressure.value}}</div>

                  <div class="no-vitals" v-else>
                    <ion-chip>
                      <ion-label>Nothing Logged</ion-label>
                    </ion-chip>
                    <br>
                    <router-link :to="{ path: '/member/vitals/add' }" class="vital-link" style="float: none;color: var(--ion-color-primary)">ADD Vitals</router-link>
                  </div>

                </ion-card>
              </ion-col>
              <ion-col width-50>
                <ion-card
                  class="vitals"
                  style="background: url(/assets/icon/vitals-temperature.svg) no-repeat, rgba(191, 0, 0, 0.65);background-size: 100px;background-position-y: bottom;"
                >
                  <span class="vital-title">Temperature</span>
                  <br />
                  <router-link :to="{ path: `/member/vitals/temperature` }" class="vital-link"
                    >VIEW STATS</router-link
                  >

                  <div class="vital-value" v-if="vitals.vitals.latest_temperature">{{vitals.vitals.latest_temperature.value}}</div>

                  <div class="no-vitals" v-else>
                    <ion-chip>
                      <ion-label>Nothing Logged</ion-label>
                    </ion-chip>
                    <br>
                    <router-link :to="{ path: '/member/vitals/add' }" class="vital-link" style="float: none;">ADD Vitals</router-link>
                  </div>
                  
                </ion-card>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col width-50>
                <ion-card class="vitals" style="background: url(/assets/icon/vitals-blood-glucose.svg) no-repeat, #cb3333; background-size: 100px;background-position-y: bottom;">
                  <span class="vital-title">Blood Glucose</span>
                  <br />
                  <router-link :to="{ path: `/member/vitals/bloodglucose` }" class="vital-link"
                    >VIEW STATS</router-link
                  >

                  <div class="vital-value" v-if="vitals.vitals.latest_blood_glucose">
                    {{vitals.vitals.latest_blood_glucose.value}}
                    <span class="vital-measurement">mmol/L</span>
                  </div>

                  <div class="no-vitals" v-else>
                    <ion-chip>
                      <ion-label>Nothing Logged</ion-label>
                    </ion-chip>
                    <br>
                    <router-link :to="{ path: '/member/vitals/add' }" class="vital-link" style="float: none;">ADD Vitals</router-link>
                  </div>

                </ion-card>
              </ion-col>
              <ion-col width-50>
                <ion-card
                  class="vitals"
                  style="background: url(/assets/icon/vitals-oxygen-saturation.svg) no-repeat, #eeeeee; color: var(--ion-color-primary); background-size: 100px;background-position-y: bottom;"
                >
                  <span class="vital-title" style="color: #000000"
                    >Oxygen Saturation</span
                  >
                  <br />
                  <router-link :to="{ path: `/member/vitals/oxygensaturation` }" class="vital-link" style="color: var(--ion-color-primary)"
                    >VIEW STATS</router-link
                  >

                  <div class="vital-value" v-if="vitals.vitals.latest_oxygen_saturation">
                    {{vitals.vitals.latest_oxygen_saturation.value}}
                    <span class="vital-measurement">%</span>
                  </div>

                  <div class="no-vitals" v-else>
                    <ion-chip>
                      <ion-label>Nothing Logged</ion-label>
                    </ion-chip>
                    <br>
                    <router-link :to="{ path: '/member/vitals/add' }" class="vital-link" style="float: none;color: var(--ion-color-primary);">ADD Vitals</router-link>
                  </div>

                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip
} from "@ionic/vue";

export default {
  name: "Vitals",
  components: {
    IonContent,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    IonChip
  },
  computed: {
    vitals() {
      return this.$store.state.vitals;
    },
    user() {
      return this.$store.state.user;
    },
  },
  ionViewDidEnter(){
    this.$store.dispatch("Vitals", this.user.id);
  },
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.vitals {
  box-shadow: none;
  color: var(--ion-color-primary-contrast);
  padding-top: 10px;
  padding-left: 13px;
  padding-right: 12px;
}

.vital-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  float: left;
}

.vital-link {
  font-size: 8px;
  line-height: 12px;
  float: left;
  color: var(--ion-color-primary-contrast);
  /* font-weight: 600; */
}

.vital-value {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  padding-top: 40px;
  padding-bottom: 50px;
}

.vital-measurement {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

ion-chip{
  background: #777777;
  border: #777777;
  color: var(--ion-color-primary-contrast);
  float: none;
  font-size: 13px;
}

.no-vitals{
  padding-top: 50px;
  padding-bottom: 60px;
}

ion-button {
  width: auto;
}

</style>